import React from 'react';
import { QuizzProps, isCorrectAnswer } from './model';
import { isMultipleChoiceQuestion, QuizzQuestion } from '../common/magoliw';

export default class Screen extends React.Component<QuizzProps> {

    private getScores(): Map<string, number> {
        const scoreByTable: Map<string, number> = new Map();
        for (let answer of this.props.serverState.answers) {
            const question = this.props.serverState.questions[answer.question];
            const isCorrect = isCorrectAnswer(this.props.serverState, answer);
            const scoreAnswer = isCorrect ? question.points : 0;
            let previousScore = scoreByTable.get(answer.user.table);
            if (previousScore === undefined) {
                previousScore = 0;
            }
            scoreByTable.set(answer.user.table, previousScore + scoreAnswer);
        }
        return scoreByTable;
    }

    // Rendering

    private renderScores() {
        const qid = this.props.serverState.qid;
        const ended = this.props.serverState.endedQuestions.has(qid);
        if (!ended) {
            return null;
        }
        const scores = this.getScores();
        const scoresEntries: [string, number][] = Array.from(scores.entries());
        scoresEntries.sort((e1, e2) => e2[1] - e1[1]);
        return <ul>
            {scoresEntries.map(se => <li key={se[0]}>{se[0]}: {se[1]} points</li>)}
        </ul>
    }

    private renderQuestionAnswers(q: QuizzQuestion, qid: number, ended: boolean) {
        if (isMultipleChoiceQuestion(q)) {
            return <div>
                {q.answers.map((a, i) => {
                    const className = ended ? i === q.correct ? 'answer-correct' : 'answer-wrong' : 'answer';
                    const countAnswer = this.props.serverState.answers.filter(a => a.question === qid && a.answer === i).length;
                    const count = !ended || countAnswer === 0 ? '' : countAnswer;
                    return <div key={i} className={className}>{a}<em className="answers-count">{count}</em></div>;
                })}
            </div>;
        } else {
            const ended = this.props.serverState.endedQuestions.has(qid);
            if (!ended) {
                return null;
            }
            return <div>
                {this.props.serverState.answers.filter(a => a.question === qid).map((a, i) => {
                    const className = isCorrectAnswer(this.props.serverState, a) ? 'answer-correct' : 'answer-wrong';
                    return <div key={i} className={className}>{a.answer}</div>
                })}
            </div>
        }
    }

    private renderQuestion() {
        const qid = this.props.serverState.qid;
        if (qid === -1) {
            return <div><p>Prêt ?</p><p>{this.props.usersCount} joueurs connectés</p></div>
        }
        const ended = this.props.serverState.endedQuestions.has(qid);
        const q = this.props.serverState.questions[qid];
        return <div>
            <h2>{q.text}</h2>
            {this.renderQuestionAnswers(q, qid, ended)}
            {this.renderScores()}
        </div>;

    }


    public render() {
        return <div><h1>Quizz</h1>{this.renderQuestion()}</div>
    }
}