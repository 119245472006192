import React from 'react';
import Admin from './Admin';
import Voter from './Voter';
import Screen from './Screen';
import wrapAccountAware, { NON_LOGGED, PropsFromAccountAware } from '../AccountAwareHOC';

import './Main.css'
import { DemoDjProps, isDemoDjAdmin } from './model';

type Props = PropsFromAccountAware & DemoDjProps & {
    usersCount: number,
};

class Main extends React.Component<Props> {

    public render() {
        if (this.props.isScreenNow()) {
            return <Screen serverState={this.props.serverState}/>;
        }
        if (isDemoDjAdmin(this.props.user)) {
            return <Admin serverState={this.props.serverState} user={this.props.user} usersCount={this.props.usersCount} />;
        }
        if (this.props.user.id === NON_LOGGED.id) {
            return <div>Pas connecté, clicke sur le lien dans le texto que tu as reçu!</div>
        }
        return <Voter serverState={this.props.serverState} user={this.props.user} />;
    }
}

export default wrapAccountAware(Main);