import React from 'react';

import { isBetweenTracks, isPlaying, isAnswering, isBefore } from "../common/magoliw";
import { BtServerState } from './model';
import { sendMessage } from '../server';

interface Props {
    serverState: BtServerState
}

interface State {
    nextToPlay: number,
}

export default class Admin extends React.Component<Props, State> {

    // LifeCycle

    public constructor(props: Props) {
        super(props);
    }

    // Event Handling

    private onStartSong() {
        sendMessage({
            type: 'bt-start-song',
        });
    }

    private onCorrect(correct: boolean) {
        sendMessage({
            type: 'bt-correction',
            correct,
        });
    }

    // Rendering

    public render() {
        const state = this.props.serverState.state;
        if (isBefore(state)) {
            return <div className="bt-admin" onClick={() => sendMessage({type: 'bt-init'})}>Révéler</div>
        }
        if (isBetweenTracks(state)) {
            const next = state.next;
            if (next === undefined) {
                return <div>
                <div className="bt-admin">Fini!</div>
            </div>;

            } else {
                return <div>
                <div className="bt-admin">
                    <div onClick={() => this.onStartSong()}>Suivante</div>
                    <div>{next.name} - {next.artist}</div>
                </div>
            </div>;

            }
        }
        if (isPlaying(state)) {
            return <div className="bt-admin">
                <div>Playing...</div>
                <div>{state.playing.name} - {state.playing.artist}</div>
            </div>;
        }
        if (isAnswering(state)) {
            return <div className="bt-admin">
                <div>{state.user.firstName} {state.user.lastName} répond</div>
                <div>{state.solution.name} - {state.solution.artist}</div>
                <div className="bt-admin-correction bt-admin-correction-wrong" onClick={() => this.onCorrect(false)}>Faux</div>
                <div className="bt-admin-correction bt-admin-correction-correct" onClick={() => this.onCorrect(true)}>Bon</div>
            </div>;
        }
    }
}