export type Locale = 'fr' | 'de' | 'en';

type GameParticipation = 'yes' | 'no' | 'maybe';

export interface User {
    id: number,
    firstName: string,
    lastName: string,
    image: string,
    table: string,
    email: string,
    tel: string,
    lang: Locale,
}

export interface ActiveUser extends User {
    lastAccess?: Date,
}

export interface AuthenticatedUser extends User {
    token: string,
}

export interface ChatMessage {
    userId: number,
    content: string,
    date: Date,
}

export interface QuizzFreeQuestion {
    text: string,
    points: number,
}

export interface QuizzMultipleChoiceQuestion {
    text: string,
    answers: string[],
    correct: number,
    points: number,
}

export type QuizzQuestion = QuizzFreeQuestion | QuizzMultipleChoiceQuestion;

export function isMultipleChoiceQuestion(q: QuizzQuestion): q is QuizzMultipleChoiceQuestion {
    return (q as QuizzMultipleChoiceQuestion).answers !== undefined;
}

export interface QuizzFreeAnswer {
    question: number,
    answer: string,
    user: User,
}

export interface QuizzMultipleChoiceAnswer {
    question: number,
    answer: number,
    user: User,
}

export type QuizzAnswer = QuizzFreeAnswer | QuizzMultipleChoiceAnswer;

export interface QuizzFreeCorrection {
    question: number,
    text: string,
    accepted: boolean,
}

export type PlayBackSource = 'spotify' | 'file' | 'none';

export interface PlayBackInformation extends TrackInfo {
    spotifyStatus: string,
    timestampSpotify: number,
    progress_ms: number,
    timestampServer: number,
}

export interface PlaylistItem extends SearchResult {
    voters: User[],
    adder: User,
}

export interface TrackInfo {
    name: string,
    album: string,
    artists: string[],
    duration_ms: number,
    source: PlayBackSource,
    image_url: string | undefined,
}

export interface SearchResult extends TrackInfo {
    id: string,
}

export interface DemoDjTheme {
    id: string,
    label: string,
    playlist: string,
    hints: string[],
    tracks: number,
}

export interface BtTrack {
    name: string,
    artist: string,
}

export interface BtScore {
    user: User,
    score: number,
}

export interface BtStatePlaying {
    state: 'playing',
    playing: BtTrack,
};

export interface BtStateAnswering {
    state: 'answering',
    user: User,
    solution: BtTrack,
}

export interface BtStateBetweenTracks {
    state: 'between-tracks',
    previous?: BtTrack,
    next?: BtTrack,
}

export interface BtStateBefore {
    state: 'before',
}

export type BtState = BtStateBetweenTracks | BtStatePlaying | BtStateAnswering | BtStateBefore;

export function isBefore(s: BtState): s is BtStateBefore {
    return s.state === 'before';
}

export function isBetweenTracks(s: BtState): s is BtStateBetweenTracks {
    return s.state === 'between-tracks';
}

export function isPlaying(s: BtState): s is BtStatePlaying {
    return s.state === 'playing';
}

export function isAnswering(s: BtState): s is BtStateAnswering {
    return s.state === 'answering';
}


export type ClientMainView = 'presentation' | 'quizz' | 'demodj' | 'blank' | 'bt';

// Client messages

export const CLIENT_TYPE_CHAT_MESSAGE = 'chat-message';
export type CLIENT_TYPE_CHAT_MESSAGE = typeof CLIENT_TYPE_CHAT_MESSAGE;
export interface WsClientChatMessage {
    type: CLIENT_TYPE_CHAT_MESSAGE,
    message: ChatMessage,
}

export interface WsClientChangeMainView {
    type: 'change-main-view',
    view: ClientMainView,
}

export interface WsClientQuizzNextQuestion {
    type: 'next-question'
}

export interface WsClientQuizzAnswer {
    type: 'quizz-answer',
    a: QuizzAnswer,
}

export interface WsClientQuizzCorrection {
    type: 'quizz-correction',
    c: QuizzFreeCorrection,
}

export interface WsClientQuizzQuestionEnd {
    type: 'quizz-question-end',
    qid: number,
}

export type WsClientQuizzMessage = WsClientQuizzNextQuestion | WsClientQuizzAnswer | WsClientQuizzCorrection | WsClientQuizzQuestionEnd;
export interface WsClientSpotifyCode {
    type: 'spotify-code',
    code: string,
    usedRedirectUri: string,
}

export interface WsClientDemoDjAddTrack {
    type: 'demodj-add-track',
    track: SearchResult,
    user: User,
}

export interface WsClientDemoDjVote {
    type: 'demodj-vote',
    trackId: string,
    voteFor: boolean,
    user: User,
}

export interface WsClientDemoDjRemoveTrack {
    type: 'demodj-remove-track',
    trackId: string,
}

export interface WsClientDemoDjNextTheme {
    type: 'demodj-next-theme',
}

export interface WsClientDemoDjIncreaseTheme {
    type: 'demodj-increase-theme',
}

export interface WsClientDemoDjNextSong {
    type: 'demodj-next-song',
}

export type WsClientDemoDjMessage = WsClientSpotifyCode | WsClientDemoDjAddTrack | WsClientDemoDjVote | WsClientDemoDjRemoveTrack | WsClientDemoDjNextTheme | WsClientDemoDjIncreaseTheme | WsClientDemoDjNextSong;
export interface WsClientBtTrackStart {
    type: 'bt-start-song',
}

export interface WsClientBtAnswer {
    type: 'bt-answer',
    user: User,
}

export interface WsClientBtCorrection {
    type: 'bt-correction',
    correct: boolean,
}

export interface WsClientBtInit {
    type: 'bt-init',
}

export type WsClientBtMessage = WsClientBtTrackStart | WsClientBtAnswer | WsClientBtCorrection | WsClientBtInit

// Server messages

export interface WsServerChatMessage {
    type: 'chat-message',
    message: ChatMessage,
}

export interface WsServerChangeMainView {
    type: 'change-main-view',
    view: ClientMainView
}

export interface WsServerQuizzNewQuestion {
    type: 'new-question',
    id: number,
}

export interface WsServerQuizzAnswerList {
    type: 'quizz-answers',
    answers: QuizzAnswer[],
}

export interface WsServerQuizzCorrectionList {
    type: 'quizz-corrections',
    corrections: QuizzFreeCorrection[],
}

export interface WsServerQuizzContent {
    type: 'quizz-content',
    questions: QuizzQuestion[],
}

export interface WsServerQuizzQuestionEnd {
    type: 'quizz-question-end',
    qid: number,
}

export type WsServerQuizzMessage = WsServerQuizzNewQuestion | WsServerQuizzAnswerList | WsServerQuizzCorrectionList | WsServerQuizzContent | WsServerQuizzQuestionEnd;

export interface WsServerMetadata {
    type: 'metadata',
    clients: number,
}

export interface WsServerRedirect {
    type: 'redirect',
}

export interface WsServerDemoDjPlaybackInfo {
    type: 'demodj-playback-info',
    info: PlayBackInformation | undefined,
}

export type Dj = {user: User, tracks: number, votes: number};

export interface WsServerDemoDjPlaylist {
    type: 'demodj-playlist',
    playlist: PlaylistItem[],
    cutAfter: number,
    bestDjs: Dj[]
}
export interface WsServerDemoDjTheme {
    type: 'demodj-theme',
    theme: DemoDjTheme,
    next: DemoDjTheme | undefined,
}

export type WsServerDemoDjMessage = WsServerDemoDjPlaybackInfo | WsServerDemoDjPlaylist | WsServerDemoDjTheme;
export interface WsServerBtState {
    type: 'bt-state',
    state: BtState,
}

export interface WsServerBtScores {
    type: 'bt-score',
    scores: BtScore[],
}

export type WsServerBtMessage = WsServerBtState | WsServerBtScores;


export type WsClientMessage = WsClientChatMessage | WsClientChangeMainView | WsClientQuizzMessage | WsClientDemoDjMessage | WsClientBtMessage;
export type WsServerMessage = WsServerChatMessage | WsServerChangeMainView | WsServerQuizzMessage | WsServerDemoDjMessage | WsServerBtMessage | WsServerMetadata | WsServerRedirect;
