import React from "react";
import { ActiveUser, User } from "../../../common/magoliw";
import wrapAccountAware, { isUserScreen, PropsFromAccountAware } from "../AccountAwareHOC";
import Button from "../Button";
import { getAllUsers, onboardUser, sendMessage, setRedirection } from "../server";

import './Onboarding.css';

type Props = PropsFromAccountAware;

interface State {
    users: ActiveUser[],
}

const LastAccess: React.FC<{d?: Date}> = ({d}) => {
    if (d === undefined) {
        return null;
    } else {
        const now = Date.now();
        const durationMs = now - d.getTime();
        const durationS = Math.floor(durationMs / 1000);
        return <div>{durationS} s ago</div>;
    }
}

type Step = 'NotLaunched' | 'Pending' | 'Failed' | 'Succeeded';

const OnboardButton: React.FC<{u: User}> = ({u}) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [target, setTarget] = React.useState<string>(u.tel);
    const [step, setStep] = React.useState<Step>('NotLaunched');
    const disabled = step === 'Pending';
    const text = step === 'NotLaunched' ? '' : step;
    if (!expanded) {
        return <div>
                <button disabled={disabled} onClick={async () => {
                    try {
                        setStep('Pending');
                        await onboardUser(u.id, u.tel);
                        setStep('Succeeded');
                    } catch (e) {
                        setStep('Failed')
                    }
                }}>{u.tel}</button>
                <button disabled={disabled} onClick={async () => {
                    try {
                        setStep('Pending');
                        await onboardUser(u.id, u.email);
                        setStep('Succeeded');
                    } catch (e) {
                        setStep('Failed');
                    }
                }}>{u.email}</button>
                <span>{text}</span>
                <button onClick={() => setExpanded(true)}>Edit</button>
            </div>
    }
    return <div className="onboard-form">
        <button onClick={() => setTarget(u.email)}>Email instead</button>
        <button onClick={() => setTarget(u.tel)}>Phone instead</button>
        <input type="text" value={target} onChange={e => setTarget(e.target.value)}></input>
        <button disabled={disabled} onClick={async () => {
            try {
                setStep('Pending');
                await onboardUser(u.id, target);
                setStep('Succeeded');
                setExpanded(false);
            } catch (e) {
                setStep('Failed');
            }
        }}>Onboard</button>
    </div>
}

async function onboardMultiple(users: ActiveUser[]) {
    for (let user of users) {
        await onboardUser(user.id, user.tel);
    }
}

class Onboarding extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {users: []};
    }

    public async componentDidMount() {
        try {
            const all = await getAllUsers();
            this.setState({users: all.filter(u => !isUserScreen(u))});
        } catch (e) {
            console.error('Could not fetch users list.');
        }
    }

    render() {
        if (!this.props.isAdminNow()) {
            return null;
        }
        const remaining = this.state.users.filter(u => u.lastAccess === undefined && u.tel !== '')
        return <div>
            <h1>Admin</h1>
            <div>
                <Button onClick={() => {
                sendMessage({type: 'change-main-view', view: 'quizz'});
            }}>Démarrer Quizz</Button>
            <Button onClick={() => {
                sendMessage({type: 'change-main-view', view: 'bt'});
            }}>Démarrer Test technique</Button>
            <Button onClick={() => {
                sendMessage({type: 'change-main-view', view: 'demodj'});
            }}>Démarrer DemoDJ</Button>
            <Button onClick={() => {
                sendMessage({type: 'change-main-view', view: 'presentation'});
            }}>Présentation</Button>
            </div>
            <h2>Redirection</h2>
            <button onClick={() => setRedirection(true)}>Enable</button>
            <button onClick={() => setRedirection(false)}>Disable</button>
            <h2>Onboarding</h2>
            <button onClick={() => onboardMultiple(remaining)}>Onboard remaining {remaining.length}</button>
            <div className="onboarding">
            {this.state.users.map(user => {
                const className = user.lastAccess === undefined ? '' : 'onboarded';
                return <React.Fragment key={user.id}>
                    <div className={className}>{user.firstName}</div>
                    <div className={className}>{user.lastName}</div>
                    <div className={className}>{user.tel}</div>
                    <div><LastAccess d={user.lastAccess}/></div>
                    <div><OnboardButton u={user}/></div>
                </React.Fragment>;
            })}
        </div>
        </div>
    }
}

export default wrapAccountAware(Onboarding);