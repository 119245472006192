import React from 'react';
import { TrackInfo } from '../../../common/magoliw';

interface Props {
    track: TrackInfo,
}

class Track extends React.Component<Props> {

    public render() {
        const {artists, album, name, image_url } = this.props.track;
        return <div className="track">
            <img alt="album" src={image_url} className="track-image"/>
            <div className="track-main-info">
                <div className="track-name">{name}</div>
                <div className="track-album">{album} - {artists.join(', ')}</div>
            </div>
            </div>
    }

}

export default Track;