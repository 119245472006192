import {useState, useEffect} from 'react';
import { AuthenticatedUser } from '../common/magoliw';
import {
    AccountInfoLoadingState,
    globalListeners,
    removeListener,
    globalAccountInfo,
    globalLoadingState,
    PropsFromAccountAware,
    NON_LOGGED,
    invalidate as invalidateAccountInfo,
    locale,
    isScreen,
    isAdmin,
    isOrga} from '../AccountAwareHOC';

function useAccount(): PropsFromAccountAware {
    const [accountInfo, setAccountInfo] = useState<AuthenticatedUser>(NON_LOGGED);
    const [loadingState, setLoadingState] = useState<AccountInfoLoadingState>('not-started');
    useEffect(() => {
        setAccountInfo(globalAccountInfo);
        setLoadingState(globalLoadingState);

        function onAccountInfoChange(accountInfo: AuthenticatedUser) {
            setAccountInfo(accountInfo);
        }
        globalListeners.push(onAccountInfoChange);
        return function cleanup() {
            removeListener(onAccountInfoChange);
        };
    }, []);
    return {
        user: accountInfo,
        accountInfoLoaded: loadingState === 'loaded',
        invalidateAccountInfo,
        isAdminNow: () => isAdmin(accountInfo),
        isOrgaNow: () => isOrga(accountInfo),
        isScreenNow: () => isScreen(accountInfo),
        locale: () => locale(accountInfo),
    }
}

export {
    useAccount
}