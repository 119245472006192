import React from 'react';
import Button from '../Button';
import { sendMessage } from '../server';


const NextTheme: React.FC<{}> = () => {
    const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false);
    if (showConfirmation) {
        return <><Button onClick={() => {
                sendMessage({type: 'demodj-next-theme'});
                setShowConfirmation(false);
        }}>Confirmer changement de thème</Button>
        <Button onClick={() => {
                setShowConfirmation(false);
        }}>Annuler changement de thème</Button>
        </>
    } else {
        return <Button onClick={() => {
                setShowConfirmation(true);
        }}>Changer de thème</Button>
    }
}

export default NextTheme;