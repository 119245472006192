import React from 'react';
import { DemoDjProps } from './model';
import { requestSpotifyAuthorization } from './spotify';
import PlaybackInformationDisplay from './PlaybackInfo';
import Voter from './Voter';
import { User } from '../../../common/magoliw';
import NextTheme from './NextTheme';
import Button from '../Button';
import { sendMessage } from '../server';

interface State {
    asVoter: boolean
}

interface Props extends DemoDjProps {
    user: User,
    usersCount: number,
}

export default class Player extends React.Component<Props, State> {

    // LifeCycle

    constructor(props: Props) {
        super(props);
        this.state = {
            asVoter: false,
        }
    }

    // Rendering

    public render() {
        if (this.state.asVoter) {
            return <Voter serverState={this.props.serverState} user={this.props.user} backToAdminFn={() => this.setState({asVoter: false})}/>
        };
        return <div className="demodj-root">
            <h1>DemoDJ Admin</h1>
            <Button onClick={() => requestSpotifyAuthorization()}>Connect Spotify</Button>
            <NextTheme />
            <Button onClick={() => sendMessage({type: 'demodj-increase-theme'})}>Prolonger thème</Button>
            <Button onClick={() => sendMessage({type: 'demodj-next-song'})}>Chanson suivante</Button>
            <Button onClick={() => sendMessage({type: 'change-main-view', view: 'demodj'})}>Mark admin</Button>
            <PlaybackInformationDisplay info={this.props.serverState.playback}/>
            <div>{this.props.usersCount} connectés</div>
            <div className="clickable" onClick={() => this.setState({asVoter: true})}>Voter</div>
        </div>
    }
}