import React from 'react';
import './Separator.css';

interface Props {
    text: string,
}


const Separator: React.FC<Props> = ({text}) => {
    return <div className="separator">
        <div className="separator-left"></div>
        <div className="separator-text">
            {text}
        </div>
        <div className="separator-right"></div>
        <div className="separator-bottom-left"></div>
        <div className="separator-bottom-right"></div>
    </div>
}

export default Separator;