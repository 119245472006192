import React from 'react';
import { QuizzQuestion, isMultipleChoiceQuestion } from '../common/magoliw';

interface Props {
    q: QuizzQuestion,
    onPickAnswer: (a: number | string) => void,
}

interface State {
    answer: string,
}

export class Question extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {answer: ''};
    }

    private renderAnswers() {
        if (isMultipleChoiceQuestion(this.props.q)) {
            return <div>
                {this.props.q.answers.map((a, i) => <div key={i} onClick={() => this.props.onPickAnswer(i)}>{a}</div>)}
            </div>;
        } else {
            return <div>
                <input type="text" value={this.state.answer} onChange={e => this.setState({answer: e.target.value})}/>
                <button onClick={() => this.props.onPickAnswer(this.state.answer)}>Répondre</button>
            </div>;
        }
    }

    public render() {
        const q = this.props.q;
        return <div>
            <h2>{q.text}</h2>
            {this.renderAnswers()}
        </div>;
    }
}