import { DemoDjTheme, PlayBackInformation, PlaylistItem, User } from "../../../common/magoliw";
import { Dj } from "../common/magoliw";

export interface DemoDjServerState {
    playback: PlayBackInformation | undefined,
    playlist: PlaylistItem[],
    bestDjs: Dj[],
    cutAfter: number,
    theme: DemoDjTheme | undefined,
    nextTheme: DemoDjTheme | undefined,
}

export interface DemoDjProps {
    serverState: DemoDjServerState,
}

export function isDemoDjAdmin(user: User) {
    return user.id === 0 || user.id === 1 || user.id === 4 || user.id === 8;
}