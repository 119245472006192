import React from 'react';

import { getAllUsers } from '../server';
import { User } from '../common/magoliw';
import './Trombinoscope.css';
import { isUserScreen } from '../AccountAwareHOC';
import UserImage from './UserImage';
import './Main.css'

type Props = {};

interface State {
    users: User[],
}

class Trombinoscope extends React.Component<Props, State> {

    // LifeCycle

    public constructor(props: Props) {
        super(props);
        this.state = {users: []};
    }

    public async componentDidMount() {
        try {
            const all = await getAllUsers();
            this.setState({users: all.filter(u => !isUserScreen(u))});
        } catch (e) {
            console.error('Could not fetch users list.');
        }
    }

    // Rendering

    private renderUser(u: User) {
        return <div key={u.id} className="trombinoscope-item">
            <UserImage user={u}/>
            <div className="user-names">
                <span className="user-firstname">{u.firstName}</span>
                <span className="user-lastname">{u.lastName}</span>
            </div>
            <div className="user-phone">{u.tel}</div>
            <div className="user-email">{u.email}</div>
        </div>
    }

    public render() {
        return <div className="trombinoscope">
            <h3>Trombinoscope</h3>
            <div className="trombinoscope-list">
                {this.state.users.map(u => this.renderUser(u))}
            </div>
        </div>
    }
}

export default Trombinoscope;