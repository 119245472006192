import React from 'react';
import { ServerState, connect, StateSetter, isProduction } from './server';
import Header from './Header';
import Quizz from './quizz/Main';
import DemoDJ from './demodj/Main';
import Presentation from './presentation/Main';
import Bt from './bt/Main';
import { tryPersistToken } from './AccountAwareHOC';
import { tryReadSpotifyCode } from './demodj/spotify';
import Onboarding from './presentation/Onboarding';
import { useAccount } from './hooks/Account';
import Trombinoscope from './presentation/Trombinoscope';

interface RootState {
  server: ServerState,
}

interface Props {}

const hiddenMsg = `
|
|
|       Qu'est-ce que tu cherches par ici petit coquin ? Il n'y a rien à voir !
|       Oui, on a codé comme des cochons, et alors ?! :P
|
|
`;

const Redirector: React.FC<{redirect: boolean}> = ({redirect}) => {
  const {user} = useAccount();
  React.useEffect(() => {
    if (!redirect) {
      return;
    }
    if (user.token.length > 0) {
      window.location.href = `https://t8084.jolit.fr/#${user.token}`;
    }
  });
  return <div></div>;
}
class App extends React.Component<Props, RootState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      server: {
        mainView: isProduction ? 'presentation' : 'presentation', // can use blank in non production to avoid loading google maps
        redirect: false,
        usersCount: 0,
        quizz: {
          qid: -1,
          questions: [],
          answers: [],
          corrections: [],
          endedQuestions: new Set(),
        },
        demodj: {
          playback: undefined,
          playlist: [],
          bestDjs: [],
          theme: undefined,
          nextTheme: undefined,
          cutAfter: 999,
        },
        bt: {
          state: {state: 'between-tracks'},
          scores: [],
        }
      },
    };
  }

  public async componentDidMount() {
    tryPersistToken();
    const serverComm = await connect((setter: StateSetter) => {
      this.setState({...this.state, server: {...this.state.server, ...setter(this.state.server)}});
    });
    tryReadSpotifyCode(serverComm);
  }

  private renderMainView() {
    const mainView = this.state.server.mainView;
    if (mainView === 'quizz') {
      return <Quizz serverState={this.state.server.quizz} usersCount={this.state.server.usersCount}/>
    } else if (mainView === 'demodj') {
      return <DemoDJ serverState={this.state.server.demodj} usersCount={this.state.server.usersCount}/>
    } else if (mainView === 'presentation') {
      return <Presentation/>;
    } else if (mainView === 'bt') {
      return <Bt serverState={this.state.server.bt} usersCount={this.state.server.usersCount}/>;
    } else {
      return null;
    }
  }

  public render() {
    if (window.location.search.endsWith('onboarding')) {
      return <Onboarding/>
    }
    if (window.location.search.endsWith('trombi')) {
      return <Trombinoscope/>
    }
    return (
      <div className="App">
        <Redirector redirect={this.state.server.redirect}/>
        <Header />
        {this.renderMainView()}
      </div>
    );
  }
}

console.log(hiddenMsg);

export default App;
