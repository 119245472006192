import React from 'react';
import { useAccount } from './hooks/Account';

interface Props {
    fr: string,
    de: string,
    en: string,
}

const Translated: React.FC<Props> = (props) => {
    return <span>{useTranslated(props)}</span>;
}

export function useTranslated(values: Props) {
    const {locale} = useAccount();
    const v = locale();
    return values[v];
}

export default Translated;