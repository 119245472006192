import React from 'react';
import { QuizzProps, isCorrectAnswer } from './model';
import { Question } from './Question';
import { sendMessage } from '../server';
import { User } from '../../../common/magoliw';
import { QuizzMultipleChoiceAnswer, QuizzFreeAnswer, QuizzAnswer } from '../common/magoliw';

interface State {
    // Stores the fact that the user picked a response before we are notified by the websocket.
    picked: Set<number>,
}

interface Props extends QuizzProps {
    user: User,
}

export default class Player extends React.Component<Props, State> {

    // LifeCycle

    constructor(props: Props) {
        super(props);
        this.state = {
            picked: new Set(),
        }
    }

    // Event Handling

    onPickAnswer(a: number | string) {
        let answer: QuizzAnswer;
        if (typeof a === 'number') {
            const mqAnswer: QuizzMultipleChoiceAnswer = {
                question: this.props.serverState.qid,
                answer: a,
                user: this.props.user,
            };
            answer = mqAnswer;
        } else {
            const fAnswer: QuizzFreeAnswer = {
                question: this.props.serverState.qid,
                answer: a,
                user: this.props.user
            };
            answer = fAnswer;
        }
        sendMessage({
            type: 'quizz-answer',
            a: answer,
        });
        const picked = new Set(this.state.picked);
        picked.add(this.props.serverState.qid);
        this.setState({picked: picked});
    }

    // Rendering

    private renderQuestion() {
        const qid = this.props.serverState.qid;
        if (qid === -1) {
            return <div>Prêt ?</div>
        }
        if (this.props.serverState.endedQuestions.has(qid)) {
            const myAnswers = this.props.serverState.answers.filter(a => a.user.table === this.props.user.table && a.question === qid);
            if (myAnswers.length === 0) {
                return <div>Aucune réponse donnée</div>;
            }
            const last = myAnswers[myAnswers.length - 1];
            switch (isCorrectAnswer(this.props.serverState, last)) {
                case true: return <div>Bien joué</div>;
                case false: return <div>Perdu</div>;
                case undefined: return <div>Suspens...</div>
            }
        }
        if (this.state.picked.has(qid) || this.props.serverState.answers.some(a => a.user.table === this.props.user.table && a.question === qid)) {
            return <div>Répondu</div>
        }
        const q = this.props.serverState.questions[qid];
        return <Question q={q} onPickAnswer={a => this.onPickAnswer(a)}/>
    }

    public render() {
        return <div className="demodj-root">
            <h1>Quizz</h1>
            {this.renderQuestion()}
        </div>
    }
}