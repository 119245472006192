import React from 'react';
import { BtServerState } from './model';
import { isBetweenTracks, isAnswering, BtTrack, isBefore} from '../common/magoliw';

interface Props {
    serverState: BtServerState,
    usersCount: number,
}

const teamMag = [1, 6, 7, 8];
export default class Screen extends React.Component<Props> {

    private renderScores() {
        const scores = this.props.serverState.scores;
        let scoreMag = 0;
        let scoreAll = 0;
        scores.forEach((score, i) => {
            if (teamMag.includes(score.user.id)) {
                scoreMag+=score.score;
            } else {
                scoreAll+=score.score;
            }
        });
        return <div className="bt-scores" onClick={() => {
            console.log(scores);
        }}>
            <div className="bt-score-user">Mag &amp; Co.</div>
            <div className="bt-score-user">All</div>
            <div className="bt-score-value">{scoreMag}</div>
            <div className="bt-score-value">{scoreAll}</div>
        </div>
    }

    private renderPreviousTrack(previous: BtTrack | undefined) {
        if (previous === undefined ) {
            return <p>Be ready</p>;
        }
        return <div>
            <span>This was:</span><br />
            <span>{previous.artist} - {previous.name}</span>
        </div>;
    }

    public render() {
        const state = this.props.serverState.state;
        if (isBefore(state)) {
            return <div className="bt-screen">
                <h1>Test technique</h1>
                <ul>
                    <li>Son</li>
                    <li>Vidéo</li>
                    <li>Réseau</li>
                    <li>Téléphones</li>
                </ul>
                <div>{this.props.usersCount} connectés</div>
            </div>
        }
        if (isBetweenTracks(state)) {
            return <div className="bt-screen">
                <h1>Blind Test</h1>
                <div className="bt-screen-main">
                    {this.renderPreviousTrack(state.previous)}
                    {this.renderScores()}
                </div>
            </div>
        }
        if (isAnswering(state)) {
            return <div className="bt-screen">
                <div className="bt-screen-answering">{state.user.firstName} answering</div>
            </div>;
        }
        return <div className="bt-screen">
            <h1>Blind Test</h1>
        </div>
    }
}