import { AuthenticatedUser, WsClientMessage, WsServerMessage, ClientMainView, SearchResult } from "./common/magoliw";
import { wait } from "./promises";
import { QuizzServerState } from "./quizz/model";
import { tryRetrieveToken } from "./AccountAwareHOC";
import { DemoDjServerState } from "./demodj/model";
import { ActiveUser } from "../../common/magoliw";
import { BtServerState } from "./bt/model";

const host = window.location.hostname;
export const isProduction = host !== 'localhost' && !host.startsWith('192.168');
export const serverUrl = isProduction ? `https://${host}` : `http://${host}:8083`;
const wsUrl = isProduction ? `wss://${host}/ws` : `ws://${host}:8083/ws` ;

export const tokenLocalStorageKey = 'token';

export async function getAccount(): Promise<AuthenticatedUser> {
    const token = tryRetrieveToken();
    const r = await fetch(`${serverUrl}/account/${token}`);
    if (r.ok) {
        const json = await r.json();
        return json;
    } else {
        console.error('Query failed', r);
        return Promise.reject('Query failed');
    }
}

export async function searchTrack(str: string): Promise<SearchResult[]> {
    const r = await fetch(`${serverUrl}/djdemo/search/${str}`);
    if (r.ok) {
        const json = await r.json();
        return json;
    } else {
        console.error('Search failed', r);
        return Promise.reject('Query failed');
    }
}

export async function getAllUsers(): Promise<ActiveUser[]> {
    const r = await fetch(`${serverUrl}/users/all`);
    if (r.ok) {
        const json: any[] = await r.json();
        json.forEach(u => {
            if (u.lastAccess !== undefined) {
                u.lastAccess = new Date(u.lastAccess);
            }
        });
        return json;
    } else {
        return Promise.reject('Query failed');
    }
}

export async function onboardUser(userId: number, ftn: string): Promise<void> {
    const r = await fetch(`${serverUrl}/users/onboard/${userId}/${ftn}`);
    if (r.ok) {
        return Promise.resolve();
    } else {
        return Promise.reject('Query failed');
    }
}

export async function setRedirection(enabled: boolean): Promise<void> {
    const r = await fetch(`${serverUrl}/redirect/${enabled}`);
    if (r.ok) {
        return Promise.resolve();
    } else {
        return Promise.reject('Query failed');
    }
}


export interface ServerState {
    mainView: ClientMainView,
    redirect: boolean,
    quizz: QuizzServerState,
    demodj: DemoDjServerState,
    bt: BtServerState,
    usersCount: number,
}

export type StateSetter = (prevState: ServerState) => Partial<ServerState>;
export type SetState = (stateSetter: StateSetter) => void;

export class ServerComm {
    socket: WebSocket;
    setState: SetState;

    constructor(socket: WebSocket, setState: SetState) {
        this.setState = setState;
        // The socket should be already open before calling this constructor.
        this.socket = socket;
        this.attachMessageListener(this.socket);
        // After one minute start checking for websocket life
        setTimeout(() => this.ensureWebSocketConnected(), 60000);
    }

    private async ensureWebSocketConnected() {
        if (this.socket.readyState !== WebSocket.OPEN && this.socket.readyState !== WebSocket.CONNECTING) {
            await this.recreateWebsocket();
        }
        setTimeout(() => this.ensureWebSocketConnected(), 10000);
    }

    private attachMessageListener(socket: WebSocket) {
        const self = this;
        socket.addEventListener('message', function (event) {
            const json = JSON.parse(event.data);
            self.processServerMessage(json);
        });
    }

    private async recreateWebsocket() {
        try {
            this.socket = await startWebsocket();
            this.attachMessageListener(this.socket);
        } catch (e) {
            // Could not start WS, server might be down or restarting, wait and retry
            await wait(2000);
            await this.recreateWebsocket();
        }
    }

    public async sendMessage(m: WsClientMessage) {
        const toSend = JSON.stringify(m);
        if (this.socket.readyState !== WebSocket.OPEN) {
            await this.recreateWebsocket();
        }
        this.socket.send(toSend);
    }

    private processServerMessage(json: WsServerMessage) {
        switch (json.type) {
            case 'change-main-view': {
                this.setState(_prev => ({mainView: json.view}));
                break;
            }
            case 'redirect': {
                this.setState(_prev => ({redirect: true}));
                break;
            }
            case 'quizz-content': {
                this.setState(prev => ({...prev, quizz: {...prev.quizz, questions: json.questions}}));
                break;
            }
            case 'new-question': {
                this.setState(prev => ({...prev, quizz: {...prev.quizz, qid: json.id}}));
                break;
            }
            case 'quizz-answers': {
                this.setState(prev => ({...prev, quizz: {...prev.quizz, answers: json.answers}}));
                break;
            }
            case 'quizz-corrections': {
                this.setState(prev => ({...prev, quizz: {...prev.quizz, corrections: json.corrections}}));
                break;
            }
            case 'quizz-question-end': {
                this.setState(prev => {
                    const ended = new Set(prev.quizz.endedQuestions);
                    ended.add(json.qid);
                    return {...prev, quizz: {...prev.quizz, endedQuestions: ended}};
                });
                break;
            }
            case 'metadata': {
                this.setState(prev => ({...prev, usersCount: json.clients}));
                break;
            }
            case 'demodj-playback-info': {
                this.setState(prev => ({...prev, demodj: {...prev.demodj, playback: json.info}}));
                break;
            }
            case 'demodj-playlist': {
                this.setState(prev => ({...prev, demodj: {...prev.demodj, playlist: json.playlist, cutAfter: json.cutAfter, bestDjs: json.bestDjs}}));
                break;
            }
            case 'demodj-theme': {
                this.setState(prev => ({...prev, demodj: {...prev.demodj, theme: json.theme, nextTheme: json.next}}));
                break;
            }
            case 'bt-score': {
                this.setState(prev => ({...prev, bt: {...prev.bt, scores: json.scores}}));
                break;
            }
            case 'bt-state': {
                this.setState(prev => ({...prev, bt: {...prev.bt, state: json.state}}));
                break;
            }
        }
    }
}

let globalServerComm: ServerComm | undefined = undefined;
type GlobalServerCommListener = (s: ServerComm) => void;
const globalListeners: GlobalServerCommListener[] = [];

export async function connect(setState: SetState): Promise<ServerComm> {
    const ws = await startWebsocket();
    const serverComm = new ServerComm(ws, setState);
    globalServerComm = serverComm;
    globalListeners.forEach(l => l(serverComm));
    globalListeners.length = 0;
    return serverComm;
}

function startWebsocket(): Promise<WebSocket> {
    return new Promise((resolve, reject) => {
        try {
            const socket = new WebSocket(wsUrl);
            let hasCalledResolve = false;
            const openListener = function(_event: Event) {
                resolve(socket);
                hasCalledResolve = true;
                socket.removeEventListener('open', openListener);
            };
            socket.addEventListener('open', openListener);
            socket.addEventListener('error', e => {
                if (hasCalledResolve) {
                    // Ignore, we cannot do anything about it, this is also handled when sending the message.
                } else {
                    reject(e);
                }
            });
        } catch (e) {
            // new WebSocket can fail
            reject(e);
        }
    });
}

export async function sendMessage(m: WsClientMessage) {
    if (globalServerComm !== undefined) {
        globalServerComm.sendMessage(m);
    } else {
        globalListeners.push(s => s.sendMessage(m));
    }
}
