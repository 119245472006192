import React from "react";

import "./Timeline.css";

import heart from "../images/timeline/icons/heart.svg"
import home from "../images/timeline/icons/home.svg"
import job from "../images/timeline/icons/job.svg"
import megaphone from "../images/timeline/icons/megaphone.svg"
import proposal from "../images/timeline/icons/proposal.svg"
import rings from "../images/timeline/icons/rings.svg"
import wine from "../images/timeline/icons/wine.svg"

const icons = [job, wine, heart, megaphone, home, proposal, rings];

const legend = [
    "Tout commence lors d'un entretien d'embauche étonnamment facile...",
    "Après quelques années, Olivier et Magalie se rapprochent lors de moultes sorties entre collègues.",
    "Un beau jour, Magalie et Olivier découvrent leur amour (pas que pour le vin !)",
    "Au bout d'un an de relation secrète à l'insu (ou presque) de leurs collègues, Olivier annonce à toute l'entreprise sa flamme pour Magalie... ",
    "...En effet, ça allait être compliqué de justifier le lendemain à la DRH pourquoi ils avaient désormais la même adresse !",
    "Pour leurs 3 ans, Olivier se jette à l'eau et fait sa demande tant attendue !",
    "Le 7 août 2021, ils se diront OUI !"
]

const dates = [
    "08.2011",
    "2016",
    "09.2016",
    "09.2017",
    "+24h",
    "09.2019",
    "08.2021"
]

const Timeline:React.FC<{}> = () => {

    return(
        <div className="timeline-container">
            <div className="timeline">
                {icons.map((ico, idx) =>
                [
                    <div key={idx} className="timeline-icon-and-text">
                        <div className="timeline-date">{dates[idx]}</div>
                        <div key={idx} className="timeline-icon">
                            <img src={ico} alt="timeline-icon"/>
                        </div>
                        <div className="timeline-text">
                            {legend[idx]}
                        </div>
                    </div>,
                    idx !== (icons.length-1) &&
                    <div key={(idx +1)*100} className="timeline-dots">
                        &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                    </div>
                ]
                )}
            </div>
        </div>
    )
}

export default Timeline;