import React from 'react';

interface Props {
    total: number
    current: number,
}

interface State {
    dateAtLastCurrent: number,
}
export default class ProgressTrack extends React.Component<Props, State> {

    private updateInterval: any | undefined; // Typescript expects type 'Timeout' but cannot find it...

    // Business Logic

    updateState() {

    }

    // LifeCycle

    constructor(props: Props) {
        super(props);
        this.state = {
            dateAtLastCurrent: Date.now(),
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.current !== this.props.current) {
            this.setState({dateAtLastCurrent: Date.now()});
        }
    }


    componentDidMount() {
        this.updateInterval = setInterval(() => this.forceUpdate(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.updateInterval);
    }

    private toMnSec(ms: number) {
        const s = Math.floor(ms / 1000);
        const mn = Math.floor(s / 60);
        const sr = s % 60;
        return `${mn}:${sr.toString().padStart(2, '0')}`
    }

    public render() {
        const now = Date.now();
        const delta = now - this.state.dateAtLastCurrent;
        const current = Math.min(this.props.total, delta + this.props.current);
        const progressWidth = current * 100 / this.props.total;
        const remainingText = this.toMnSec(this.props.total - current);
        return <div className="progress-track">
                <div className="progress-track-bar">
                    <div className="progress-track-done" style={{width: `${progressWidth}%`}} />
                </div>
                <div className="progress-track-remaining">{remainingText}</div>
        </div>;

    }
}