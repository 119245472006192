import React from 'react';
import { PlayBackInformation } from '../../../common/magoliw';
import ProgressTrack from './ProgressTrack';

interface Props {
    info: PlayBackInformation | undefined,
}

class PlaybackInformationDisplay extends React.Component<Props> {

    public render() {
        if (this.props.info === undefined) {
            return <div>Aucun morceau en cours.</div>
        }
        if (this.props.info.spotifyStatus !== '') {
            return <div>Erreur Spotify: {this.props.info.spotifyStatus}</div>
        }
        const {artists, duration_ms, image_url, name, progress_ms} = this.props.info;
        return <div className="playback-information">
            <div className="playback-image-holder">
                <img alt="album" className="playback-image" src={image_url}/>
            </div>
            <div className="playback-detail">
                <div>{name}</div>
                <div>{artists.join(', ')}</div>
                <ProgressTrack total={duration_ms} current={progress_ms} />
            </div>
        </div>
    }

}

export default PlaybackInformationDisplay;