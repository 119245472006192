import React from 'react';
import { BtServerState } from './model';
import { sendMessage } from '../server';
import { User, isAnswering, isBetweenTracks, isBefore } from '../common/magoliw';
import Translated, { useTranslated } from '../Translated';

interface Props {
    serverState: BtServerState,
    user: User,
}

function onBuzz(user: User) {
    sendMessage({type: 'bt-answer', user});
}

const Player: React.FC<Props> = ({serverState, user}) => {
    const bipText = useTranslated({fr: 'J\'ai!', de: 'BIP!', en: 'BIP!'});
    const inBetweenTracksText = useTranslated({fr: 'Prêt ?', de: 'Los ?', en: 'Ready ?'});
    const answersText = useTranslated({fr: '_ répond', de: '_ antwortet', en: '_ answers'});
    const giveAnswerText = useTranslated({fr: 'Parle!', de: 'Sprich!', en: 'Speak up!'});
    const state = serverState.state;
    if (isBefore(state)) {
        return <div>
            <h1><Translated fr="Test technique" de="Technishe Test" en="Technical Test" /> #{Math.floor(Math.random()*100)}</h1>
            <div>
                <span className="bt-tech-key"><Translated fr="Prénom" de="Vorname" en="Firstname"/></span>
                <span>{user.firstName}</span>
                <br />
                <span className="bt-tech-key"><Translated fr="Nom" de="Name" en="Lastname"/></span>
                <span>{user.lastName}</span>
            </div>
        </div>
    }
    if (isBetweenTracks(state)) {
        return <div className="bt-player">
                <h1>Blind Test</h1>
                <div className="bt-waiting-player">
                    {inBetweenTracksText}
                </div>
            </div>
    }
    let buzzerClass = 'bt-buzzer-no-answer';
    let text = bipText;
    if (isAnswering(state)) {
        const answerer = state.user;
        if (answerer.id === user.id) {
            buzzerClass = 'bt-buzzer-answerer'
            text = giveAnswerText;
        } else {
            buzzerClass = 'bt-buzzer-waiter'
            text = answersText.replace('_', answerer.firstName);
        }
    }
    return <div className="bt-player">
        <h1>Blind Test</h1>
        <div>
            <div className={`bt-buzzer-holder`}>
            <div className={`bt-buzzer ${buzzerClass}`}>
                <div className="bt-buzzer-content" onClick={() => onBuzz(user)}>{text}</div>
            </div>
        </div>
        </div>
    </div>
}

export default Player;