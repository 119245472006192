import React from 'react';
import wrapAccountAware, { PropsFromAccountAware } from "./AccountAwareHOC";
import './Header.css';

class Header extends React.Component<PropsFromAccountAware> {

    public render() {
        if (this.props.isScreenNow()) {
            return null;
        }
        return <div className="header">{this.props.user.firstName}</div>
    }

}

export default wrapAccountAware(Header);