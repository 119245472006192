import React from 'react';
import { DemoDjProps } from './model';
import PlaybackInformationDisplay from './PlaybackInfo';
import ThemeDisplay from './Theme';
import Track from './Track';
import likeHighlightIcon from '../images/like-highlight.svg';
import Separator from './Separator';
import { Dj } from '../common/magoliw';
import UserImage from '../presentation/UserImage';
import logo from '../images/logo-ddj.png';

const Podium: React.FC<{bestDjs: Dj[]}> = ({bestDjs}) => {
    return <div className="dj-podium">
        {bestDjs.map((dj, pos) => <div className={`dj dj-${pos+1}`} key={dj.user.id}>
            <span className="dj-ranking">{pos + 1}</span>
            <UserImage user={dj.user} radius={45} />
            <div className="dj-stats">
                <span className="dj-firstname">{dj.user.firstName}</span>
                <div><span className="dj-stat">{dj.tracks}</span> suggestions</div>
                <div><span className="dj-stat">{dj.votes}</span> votes</div>
            </div>
        </div>)}
    </div>;
}

export default class Player extends React.Component<DemoDjProps, {}> {

    // Rendering

    private renderPlaylist() {
        const cutAfter = this.props.serverState.cutAfter;
        const spliceLen = cutAfter === -1 ? this.props.serverState.playlist.length - cutAfter: 0;
        const tracks = this.props.serverState.playlist.map((pi, i) => {
            const after = i > this.props.serverState.cutAfter;
            const classNameAfter = after ? ' playlist-item-after' : '';
            return <div key={pi.id}  className={`playlist-item ${classNameAfter}`} >
                <Track track={pi} />
                <div className="track-vote">
                    <div className="track-vote-counter">
                        <span>{pi.voters.length}</span>
                        <img alt="votes" src={likeHighlightIcon}/>
                    </div>
                </div>
            </div>;
            });
        if (cutAfter >= -1 && cutAfter < this.props.serverState.playlist.length - 1) {
            tracks.splice(cutAfter + 1, spliceLen, <Separator key="sep" text="Changement de thème"/>);
    }
    return <div>{tracks}</div>
    }


    public render() {
        return <div className="demodj-root">
            <div className="demodj-screen">
                <div className="demodj-screen-left">
                <div className="demodj-left-logo demodj-logo">
                    <img src={logo} alt="Logo"/>
                </div>
                    <ThemeDisplay theme={this.props.serverState.theme} nextTheme={this.props.serverState.cutAfter === -1 ? this.props.serverState.nextTheme : undefined} />
                    <div className="dj-podium-header demodj-header">Top 5 des DJs de la soirée</div>
                    <Podium bestDjs={this.props.serverState.bestDjs}/>
                </div>
                <div className="demodj-screen-right">
                    <PlaybackInformationDisplay info={this.props.serverState.playback}/>
                    <div className="playlist-header demodj-header">Prochains titres</div>
                    {this.renderPlaylist()}
                </div>
            </div>
        </div>
    }
}