import React from 'react';
import { DemoDjTheme } from '../../../common/magoliw';
import Translated from '../Translated';
import logo from '../images/logo-ddj.png';

interface Props {
    theme: DemoDjTheme | undefined,
    nextTheme: DemoDjTheme | undefined,
}

interface State {
    hintId: number,
}

function getHintPos(i: number, current: number, count: number) {
    if (i === current) {
        return 'curr';
    }
    if ((i + 1) % count === current) {
        return 'prev';
    }
    if ((current + 1) % count === i) {
        return 'next';
    }
    return 'bott';
}

const updateHintPeriodMs = 10000;

class ThemeDisplay extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {hintId: 0};
        setTimeout(() => this.updateHint(), updateHintPeriodMs);
    }

    private updateHint() {
        const hintsCount = this.props.theme === undefined ? 2 : this.props.theme.hints.length
        this.setState(prev => ({hintId: (prev.hintId + 1) % hintsCount}));
        setTimeout(() => this.updateHint(), updateHintPeriodMs);
    }

    public render() {
        if (this.props.theme === undefined) {
            return null;
        }
        const current = this.state.hintId;
        const header = this.props.nextTheme === undefined ? <Translated fr="thème" en="theme" de="theme"/> : <Translated fr="thème suivant" en="next theme" de="folgende theme"/>
        const {label, hints} = this.props.nextTheme === undefined ? this.props.theme : this.props.nextTheme;
        // If there are less than 3 hints other hints will transition from prev to next withough going through bottom which will make them visible in the UI
        const displayedHints = hints.length > 3 ? hints : [...hints, ''];
        const count = displayedHints.length;
        return <div className="theme">
            <div className="demodj-logo">
                <img src={logo} alt="Logo"/>
            </div>
            <div id="theme-header" className="demodj-header">{header}</div>
            <div id="theme-value">{label}</div>
            <div className="theme-hints">
                {displayedHints.map((hint, i) => {
                    const className = `theme-hint theme-hint-${getHintPos(i, current, count)}`;
                    return <div key={i} className={className}>{hint}</div>;
                })}
            </div>
        </div>;
    }
}

export default ThemeDisplay;