
import React from 'react';

import './SideBySide.css';
import barH from '../images/bar_h.jpg';
import bottom from '../images/bottom_light.jpg';

interface PropsFromParent {
    title: string,
    image: string,
    textRight: boolean,
    // titleColor: string,
}

type Props = PropsFromParent;


class SideBySide extends React.Component<Props> {
    
    public render() {
        const contentPadding = this.props.textRight ? '60px 0 60px 60px' : '60px 60px 60px 0';
        const barTransform = this.props.textRight ? 'scaleX(-1)': undefined;
        const flexDirection = this.props.textRight ? 'row' : 'row-reverse';
        const bottomTransform = this.props.textRight ? undefined : 'scaleX(-1)';
        const imageMarginLeft = this.props.textRight ? 50 : 0;
        const titleMargin = this.props.textRight ? '-30px 0 -10px 60px' : '-30px 60px -10px 0';
        return <div className="sideBySide" style={{flexDirection}}>
            <div className="side-img">
                <div className="img-with-decoration">
                    <img className="side-img-main" src={this.props.image} style={{marginLeft: imageMarginLeft, position: 'relative', zIndex: 20}} alt=""/>
                    <img src={bottom} alt="bottom angle" className="side-img-decoration" style={{transform: bottomTransform}}/>
                </div>
                <div style={{flex: 1}}>&nbsp;</div>
            </div>
            <img src={barH} alt="horizontal bar" style={{position: 'absolute', top: 50, width: '100%', height: 4, transform: barTransform, zIndex: 10}}/>
            <div className="side-text">
                <h2 style={{margin: titleMargin}}>{this.props.title}</h2>
                <div style={{
                    minHeight: 200,
                    padding: contentPadding }}>
                    {this.props.children}
                </div>
            </div>
        </div>
    }
}

export default SideBySide;