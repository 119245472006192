import React from 'react';
import { User } from '../../../common/magoliw';


type Props = {
    user: User,
    radius?: number,
};

function getDefaultFileName(user: User) {
    return `${user.firstName}-${user.lastName}.jpg`.replace(' ', '-').toLowerCase();
}

export function getSource(user: User) {
    const fileName = user.image === '' ? getDefaultFileName(user) : user.image;
    return `/people/${fileName}`;
}

class UserImage extends React.Component<Props> {

    // Rendering

    public render() {
        const radius = this.props.radius === undefined ? 90 : this.props.radius;
        return <div className="user-image">
            <img style={{width:radius*2, borderRadius: radius}} alt={`portrait de ${this.props.user.firstName} ${this.props.user.lastName}`} src={getSource(this.props.user)} />
        </div>
    }
}

export default UserImage;