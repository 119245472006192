import React, { useState } from 'react';
import debounce from 'lodash.debounce';

import { sendMessage, searchTrack } from '../server';
import { User, SearchResult, PlaylistItem } from '../../../common/magoliw';
import { DemoDjProps, DemoDjServerState, isDemoDjAdmin } from './model';
import Track from './Track';
import ProgressTrack from './ProgressTrack';
import ThemeDisplay from './Theme';
import Button from '../Button';
import Translated, {useTranslated} from '../Translated';
import Separator from './Separator';
import likeWhiteIcon from '../images/like-white.svg';
import likeHighlightIcon from '../images/like-highlight.svg';

interface Props extends DemoDjProps {
    user: User,
    backToAdminFn?: () => void,
}

async function onSearchNotDebounced(s: string, setSearchResults: (results: SearchResult[]) => void, setSearching: (s: boolean) => void, setSearchText: (t: string) => void) {
    if (s.length < 2) {
        setSearchResults([]);
        setSearchText(s);
        return;
    }
    setSearching(true);
    const result = await searchTrack(s);
    setSearchText(s);
    setSearchResults(result);
    setSearching(false);
}

const onSearch = debounce(onSearchNotDebounced, 1000);

function onAddTrack(track: SearchResult, user: User, setAddMenuOpen: (o: boolean) => void) {
    sendMessage({type: 'demodj-add-track', track: track, user});
    setAddMenuOpen(false);
}

function onVote(playlistItem: PlaylistItem, serverState: DemoDjServerState, user: User) {
    if (serverState.cutAfter === -1) {
        return;
    }
    const hadAlreadyVoted = playlistItem.voters.some(v => v.id === user.id);
    sendMessage({type: 'demodj-vote', trackId: playlistItem.id, voteFor: !hadAlreadyVoted, user});
}

function renderSearchResult(
    inputText: string,
    searchText: string,
    searching: boolean,
    result: SearchResult[],
    user: User,
    setAddMenuOpen: (open: boolean) => void) {
    if (searching) {
        return <Translated fr="Recherche..." en="Searching..." de="Suche..."/>
    }
    if (searchText !== inputText) {
        // In debounce
        return <Translated fr="Recherche..." en="Searching..." de="Suche..."/>
    }
    if (searchText !== '' && result.length === 0) {
        return <Translated fr="Aucun résultat" en="No result" de="Kein Ergebnis" />
    }
    return <>
    {result.map(sr => <div key={sr.id} onClick={() => onAddTrack(sr, user, setAddMenuOpen)}><Track track={sr} /></div>)}
    </>
}

const AddMenu: React.FC<{user: User, addMenuOpen: boolean, setAddMenuOpen: React.Dispatch<React.SetStateAction<boolean>>}> = ({user, addMenuOpen, setAddMenuOpen}) => {
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [searching, setSearching] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [inputText, setInputText] = useState<string>('');
    if (addMenuOpen) {
        return <div>
            <div className="track-search-menu">
                <Translated fr="Chercher" en="Search" de="Suchen"/>:
                <input type="text" onChange={(e) => {
                    onSearch(e.target.value, setSearchResults, setSearching, setSearchText);
                    setInputText(e.target.value);
                }}/>
                <div onClick={() => setAddMenuOpen(false)}>X</div></div>
            <div className="track-search-results">
                {renderSearchResult(inputText, searchText, searching, searchResults, user, setAddMenuOpen)}
            </div>
        </div>
    } else {
        return <div className="add-track-button">
            <Button onClick={() => setAddMenuOpen(true)}><Translated fr="Ajouter une chanson" en="Add track" de="Lied hinzufügen"/></Button>
        </div>;
    }
}

function renderCurrentTrack(
    serverState: DemoDjServerState,
) {
    if (serverState.playback === undefined) {
        return null;
    }
    return <div className="playback-current">
        <Track track={serverState.playback}/>
        <div style={{flex: 1}}>
            <ProgressTrack total={serverState.playback.duration_ms} current={serverState.playback.progress_ms} />
        </div>
    </div>;
}

function renderPlaylist(
    user: User,
    serverState: DemoDjServerState,
    addMenuOpen: boolean,
    nextPlayingSeparator: string,
    endThemeText: string,
    endThemeVoteText: string,
) {
    if (addMenuOpen) {
        return null;
    }
    const cutAfter = serverState.cutAfter;
    const tracks = serverState.playlist.map((pi, i) => {
        const hasVotedFor = pi.voters.some(v => v.id === user.id);
        const actionClassName = hasVotedFor ? 'track-vote-action-neg' : 'track-vote-action';
        const after = i > cutAfter;
        const classNameAfter = after ? ' playlist-item-after' : '';
        let removeTrack = null;
        if (isDemoDjAdmin(user)) {
            removeTrack = <div className="track-rm" onClick={() => {sendMessage({type: 'demodj-remove-track', trackId: pi.id})}}>X</div>
        }
        return <div key={pi.id} className={`playlist-item ${classNameAfter}`}>
            <Track track={pi} />
            <div className="track-vote">
                <div className="track-vote-counter">
                    <span>{pi.voters.length}</span>
                    <img alt="votes" src={likeHighlightIcon}/>
                </div>
                <div className={actionClassName} onClick={() => onVote(pi, serverState, user)}>
                    <img alt="vote" src={likeWhiteIcon}/>
                </div>
                {removeTrack}
            </div>
        </div>;
    });
    const spliceLen = cutAfter === -1 ? tracks.length - cutAfter: 0;
    const endThemeVote = cutAfter === -1 ? null : <div className="playlist-cut-vote">{endThemeVoteText}</div>
    if (cutAfter >= -1 && cutAfter < serverState.playlist.length - 1) {
        tracks.splice(cutAfter + 1, spliceLen, <div key="sep">
            <Separator text={endThemeText}/>
            {endThemeVote}
        </div>)
    }
    const nextPlayingSeparatorEl = cutAfter === -1 ? null : <Separator text={nextPlayingSeparator} />;
    return <div className="voter-playlist">
        {renderCurrentTrack(serverState)}
        {nextPlayingSeparatorEl}
        <div className="voter-playlist-after">
            {tracks}
        </div>
    </div>
}

function renderAdminLink(
    backToAdminFn?: () => void,
) {
    if (backToAdminFn !== undefined) {
        return <Button onClick={() => {if (backToAdminFn !== undefined) {backToAdminFn();}}}>Admin</Button>
    }
}

const Player: React.FC<Props> = ({user, backToAdminFn, serverState}) => {
    const [addMenuOpen, setAddMenuOpen] = useState<boolean>(false);
    const currentlyPlaying = useTranslated({fr: 'en cours', en: 'currently playing', de: 'gerade abgespielt'});
    const nextPlaying = useTranslated({fr: 'à suivre', en: 'next', de: 'nächsten'});
    const endTheme = useTranslated({fr: 'fin du thème', en: 'theme end', de: 'Theme Ende'});
    const endThemeVote = useTranslated({
        fr: 'Les chansons suivantes ne seront pas jouées si tu ne votes pas pour elles !',
        en: 'The next tracks won\'t be played, unless you vote for them!',
        de: 'Die nächsten Lieden werden nicht gespielt. Stimmen für sie um sie zu hören'})
    return <div className="demodj-root">
        {renderAdminLink(backToAdminFn)}
        <ThemeDisplay theme={serverState.theme} nextTheme={serverState.cutAfter === -1 ? serverState.nextTheme : undefined} />
        <Separator text={currentlyPlaying} />
        {renderPlaylist(user, serverState, addMenuOpen, nextPlaying, endTheme, endThemeVote)}
        <AddMenu {...{user, addMenuOpen, setAddMenuOpen}}/>
    </div>;
}

export default Player;