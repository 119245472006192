import { ServerComm } from "../server";

const clientId = '831ff3b92b0d4f08957dfd53dc01f553';

export function getRedirectUri() {
    return `${window.location.origin}/spotify-callback`;
}

function extractSpotifyCodeFromLocation(): string|undefined {
    if (!window.location.pathname.includes('spotify-callback')) {
        return;
    }
    const search = window.location.search;
    if (search.startsWith('?code=')) {
        const code = search.substring('?code='.length);
        return code;
    }
    return undefined;
}

const LOCATION_AFTER_SPOTIFY_CONNECT_KEY = 'hrefAfterSpotifyConnect';

export function requestSpotifyAuthorization() {
    const redirectUri = getRedirectUri();
    const scopes = ['streaming', 'user-modify-playback-state', 'user-read-currently-playing', 'user-read-playback-state', 'playlist-modify-private', 'playlist-modify-public', 'playlist-read-private'];
    sessionStorage.setItem(LOCATION_AFTER_SPOTIFY_CONNECT_KEY, window.location.href);
    window.location.href = `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes}`;
}

// This is used when the web UI receives the Spotify code, which happens only in dev
// In production the server receives the code directly.
export function tryReadSpotifyCode(serverComm: ServerComm) {
    const code = extractSpotifyCodeFromLocation();
    if (code === undefined) {
        return;
    }
    serverComm.sendMessage({type: 'spotify-code', code, usedRedirectUri: getRedirectUri()});
    const redirectBack = sessionStorage.getItem(LOCATION_AFTER_SPOTIFY_CONNECT_KEY);
    if (redirectBack !== null) {
        window.location.href = redirectBack;
    }
}
