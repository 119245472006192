import React from 'react';

import './Main.css'
import CountDown from './Countdown';
import SideBySide from './SideBySide';
import Timeline from './Timeline';

import banner from '../images/main_banner.jpg';
import tabobine from '../images/tabobine.jpg';
import capvert from '../images/capvert.jpg';
import nord1 from '../images/nord1.jpg';
import nord2 from '../images/nord2.jpg';
import berlin1 from '../images/berlin.jpg';
import ski from '../images/ski.jpg';
import sardaigne from '../images/sardaigne.jpg';
import hambourg from '../images/hambourg.jpg';
import muzy from '../images/muzy.jpg';
import taiwan from '../images/taiwan.jpg';
import train from '../images/train.svg';
import airplane from '../images/airplane.svg';
import association from '../images/liste/association.jpg';
import chili from '../images/liste/chili.jpg';
import poeles from '../images/liste/poeles.jpg';
import wrapAccountAware, { PropsFromAccountAware } from '../AccountAwareHOC';
import Trombinoscope from './Trombinoscope';


type Props = PropsFromAccountAware;

interface State {
    images: string[],
}

const allImages = [capvert, hambourg, sardaigne, taiwan, ski, berlin1, nord2, muzy, nord1];
const nl = '%0D%0A'
const rsvpMailToHref = `mailto:magalie.et.olivier@jolit.fr?subject=RSVP mariage&body=Merci pour votre invitation, on ne raterait ça pour rien au monde !
${nl}${nl}
On arrivera le _____ à ___ h, et on repartira le ___ vers ___ h.${nl}
On sera là pour :${nl}
- le barbecue de vendredi soir ? ___${nl}
- le petit déjeuner ? ___${nl}
- le déjeuner ? ___${nl}
- le dîner de samedi ? ___${nl}
- le brunch ? ___${nl}
- le dîner de dimanche ? ___${nl}
Nos restrictions alimentaires : ______________ ${nl}
Pour le logement (choisir une des deux options) : ${nl}
- on s'en occupera nous même (hôtel ou tente)${nl}
OU ${nl}
- on fait confiance aux organisateurs${nl}
${nl}
Noms des invités : _______ _______`;
const mailToHref = `mailto:magalie.et.olivier@jolit.fr?subject=Vous pouvez répéter la question ?`;

class Main extends React.Component<Props, State> {

    // WeekEnd ToDoList:
    // - Piscine
    // - Allergies ?
    // - Carte
    // - Horaires
    // - Que faire dans la région
    // - Liste de mariage

    public constructor(props: Props) {
        super(props);
        const images = allImages.slice();
        this.state = {
            images,
        };
    }

    private renderTrombinoscope() {
        if (this.props.isOrgaNow()) {
            return <Trombinoscope/>
        } else {
            return null;
        }
    }

    private renderOnboardingLink() {
        if (this.props.isAdminNow()) {
            return <a id="onboarding-link" href="index.html?onboarding">OB</a>
        } else {
            return null;
        }
    }

    public render() {
        if (this.props.isScreenNow()) {
            return <div className="presentation-screen"><div>Magalie &amp; Olivier</div></div>
        }
        return <div className="announcement">
            <img id="banner" src={banner} alt="MagOli <3" title="MagOli <3" />
            <header>
                <div className="main-title">
                    <h1>Magalie &amp; Olivier</h1>
                    <h2>&bull; 7 Août 2021 &bull;</h2>
                </div>
                <CountDown />
            </header>
            <main>
                <Timeline />
                <SideBySide title="Bienvenue !" image={this.state.images[0]} textRight={true}>
                    <section id="presentation">
                        <p>
                            Nous sommes très heureux de vous inviter à notre mariage. Vous trouverez ici toutes les informations utiles pour répondre à notre invitation et préparer votre venue au mieux.
                        </p>
                        <p>
                            Nous aimerions avoir un petit mariage convivial dans l'espoir de profiter de la présence de chacun d'entre vous.
                            Dans cette optique, nous célebrerons le mariage dans un domaine possèdant plusieurs logements, disponibles pendant 3 nuits.
                            Ainsi, on espère que vous serez nombreux à nous rejoindre dès le vendredi jusqu'au lundi matin.
                        </p>
                        <p>
                            Le Jura est le pays d'origine de Madame Madeleine Jolit, grand-mère du futur marié.
                            Avec Michel Jolit elle y habite depuis plus de 30 ans et leur petit-fils y a donc passé d'innombrables vacances.
                            La future mariée ayant très vite succombé à la beauté des paysages Jurassiens et sa gastronomie, il était évident pour eux de s'unir dans cette magnifique région.
                            </p>
                        <p>
                            Nous souhaitons partager ce petit paradis avec vous, en espérant que vous tomberez aussi sous son charme verdoyant&nbsp;!
                        </p>
                    </section>
                </SideBySide>

                <SideBySide title="Déroulement" image={this.state.images[2]} textRight={true}>
                    <section id="planning">
                        <h3>Vendredi 6</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="time">Aprèm'</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Décoration de la salle puis détente dans la piscine</td></tr>
                                <tr>
                                    <td className="time">Dîner</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Barbecue</td></tr>
                                <tr>
                                    <td className="time">Soirée</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Bla Bla &amp; Jeux de société</td></tr>
                            </tbody>
                        </table>
                        <h3>Samedi 7</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="time">8h - 10h</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Petit-déjeuner</td></tr>
                                <tr>
                                    <td className="time">12h</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Déjeuner buffet</td></tr>
                                <tr>
                                    <td className="time">14h35</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Départ pour la mairie</td></tr>
                                <tr>
                                    <td className="time">15h30</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Mariage civil à la <a href="#mairie-map">mairie de Voiteur</a></td></tr>
                                <tr>
                                    <td className="time">16h</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Vin d'honneur au <a href="#framboisiere-map">12 rue du Four</a> (à 200m de la Mairie)</td></tr>
                                <tr>
                                    <td className="time">17h</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Cortège pour se rendre au <a href="#domaine-map">domaine du Moulin Rouge</a> (environ 35min en voiture)</td></tr>
                                <tr>
                                    <td className="time">18h</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Surprise</td></tr>
                                <tr>
                                    <td className="time">19h</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Apéritif</td></tr>
                                <tr>
                                    <td className="time">20h</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Repas</td></tr>
                                <tr>
                                    <td className="time">23h</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Soirée</td></tr>
                            </tbody>
                        </table>
                        <h3>Dimanche 8</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="time">0h - ?h</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Soirée (suite et fin).</td></tr>
                                <tr>
                                    <td className="time">12h</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Brunch</td></tr>
                                <tr>
                                    <td className="time">Aprèm'</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Bla Bla, Jeux d'extérieur, Piscine &amp; Bronzette</td></tr>
                                <tr>
                                    <td className="time">Soirée</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Karaoké ou soirée posée en fonction de l'humeur de chacun.</td></tr>
                            </tbody>
                        </table>
                        <h3>Lundi 9</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="time">Journée</td>
                                    <td className="sep">&bull;</td>
                                    <td className="description">Visites dans la région pour les plus courageux.</td></tr>
                            </tbody>
                        </table>
                    </section>
                </SideBySide>

                <SideBySide title="Infos pratiques" image={this.state.images[3]} textRight={false}>
                    <h3>Dress Code</h3>
                    <p>Venez sur votre 31 mais n'oubliez pas votre <strong>maillot de bain</strong>&nbsp;!&nbsp;&#128521;</p>
                    <h3>Accès</h3>
                    <p>Il n'y a pas de transports en commun autour du domaine, il faudra donc venir motorisé.</p>
                    <p>
                        <img src={airplane} alt="aéroport" className="list-icon" />L'aéroport le plus proche est celui de <strong>Lyon Saint-Exupéry</strong>, à 1h15 de voiture.<br />
                        <img src={train} alt="train" className="list-icon" />La gare de train la plus pratique est à <strong><a href="https://goo.gl/maps/4TdxGKghmQhqxJ4a9">Saint-Amour</a></strong>
                        &nbsp;(promis, on n'a pas fait exprès), à 11 minutes en voiture de Cuiseaux. On pourra venir vous y chercher en voiture. Sinon la gare de Bourg-en-Bresse est mieux desservie, et vous pouvez y <a href="https://cars.booking.com/SearchResults.do?country=France&doYear=2020&doFiltering=true&pickDate=07%2f08%2f2020+10%3a00%3a00&dropDate=10%2f08%2f2020+10%3a00%3a00&fromLocChoose=true&filterTo=49&adcamp=&ftsType=T&ftsKey=992435516&affiliateCode=booking-cars&lat=46.2001&ftsLocationSearch=8486&preflang=fr&lng=5.21496&searchType=T&doDay=10&filterFrom=0&puMonth=8&prefcurrency=EUR&puDay=7&driverAge=30&puHour=10&dropFtsEntry=992435516&aid=1572704&distance=10&city=undefined&ftsEntry=992435516&serverName=cars.booking.com&locName=Gare+de+Bourg+En+Bresse&driversAge=30&ftsAutocomplete=Gare+de+Bourg+En+Bresse&sid=cfd0b25d0e0a26c9a8c86c11633c7712&doMinute=0&bcom_origin=carslp&puYear=2020&locationName=Gare+de+Bourg+En+Bresse&puMinute=0&ftsInput=Gare+de+Bourg+En+Bresse&coordinates=46.2001%2c5.21496&adplat=booking-google&label=brand-booking-com-mIGkUzz0xOpSBcQ7qPoibgS315720306683&doHour=10&token=cMIu0KUptnqdkomZ14HGFhPhT0R2OZFosBOowScnmXgxNTc4MjMwMjcw&ftsLocationName=Gare+de+Bourg+En+Bresse&ftsSearch=L&location=8486&locID=8486&doMonth=8&filterName=CarCategorisationSupplierFilter" target="_blank" rel="noopener noreferrer">louer une voiture</a>. Elle est à 40 mns de Cuiseaux.<br />
                    </p>
                    <p>
                        Plusieurs agences de location de voitures sont disponibles <a href="https://www.lyonaeroports.com/acces-transports/voitures-de-location" target="_blank" rel="noopener noreferrer">à l'aéroport de Lyon</a> ainsi qu'<a href="https://cars.booking.com/SearchResults.do?country=France&doYear=2020&doFiltering=true&pickDate=07%2f08%2f2020+10%3a00%3a00&dropDate=10%2f08%2f2020+10%3a00%3a00&fromLocChoose=true&filterTo=49&adcamp=&ftsType=T&ftsKey=992435516&affiliateCode=booking-cars&lat=46.2001&ftsLocationSearch=8486&preflang=fr&lng=5.21496&searchType=T&doDay=10&filterFrom=0&puMonth=8&prefcurrency=EUR&puDay=7&driverAge=30&puHour=10&dropFtsEntry=992435516&aid=1572704&distance=10&city=undefined&ftsEntry=992435516&serverName=cars.booking.com&locName=Gare+de+Bourg+En+Bresse&driversAge=30&ftsAutocomplete=Gare+de+Bourg+En+Bresse&sid=cfd0b25d0e0a26c9a8c86c11633c7712&doMinute=0&bcom_origin=carslp&puYear=2020&locationName=Gare+de+Bourg+En+Bresse&puMinute=0&ftsInput=Gare+de+Bourg+En+Bresse&coordinates=46.2001%2c5.21496&adplat=booking-google&label=brand-booking-com-mIGkUzz0xOpSBcQ7qPoibgS315720306683&doHour=10&token=cMIu0KUptnqdkomZ14HGFhPhT0R2OZFosBOowScnmXgxNTc4MjMwMjcw&ftsLocationName=Gare+de+Bourg+En+Bresse&ftsSearch=L&location=8486&locID=8486&doMonth=8&filterName=CarCategorisationSupplierFilter" target="_blank" rel="noopener noreferrer">à la gare de Bourg-en-Bresse</a>.
                    </p>
                    <h4 id="domaine-map">Domaine du moulin rouge&nbsp;:</h4>
                    <iframe title="Carte accès Domaine du Moulin Rouge" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10986.223013470808!2d5.3554824!3d46.4971084!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x79a98ec77d4b8967!2sLe%20Domaine%20du%20Moulin%20Rouge!5e0!3m2!1sfr!2sfr!4v1577295046407!5m2!1sfr!2sfr" width="100%" height="320" style={{border:0}}></iframe>
                    <h4 id="mairie-map">Mairie de Voiteur&nbsp;:</h4>
                    <iframe title="Carte accès Maire Voiteur" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2733.502696654686!2d5.609386915809059!3d46.7549869544081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478d266e37a3fa5d%3A0x4b9977b49eeaa1db!2sMairie%20de%20Voiteur!5e0!3m2!1sfr!2sfr!4v1577295354062!5m2!1sfr!2sfr" width="100%" height="320" style={{border:0}}></iframe>
                    <h4 id="framboisiere-map">Vin d'honneur&nbsp;:</h4>
                    <iframe title="Carte 12 rue du Four" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2733.549951337143!2d5.61354555138958!3d46.75405535436916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478d26656f42e2c3%3A0xe77408f66b005978!2s12%20Rue%20du%20Four%2C%2039210%20Voiteur!5e0!3m2!1sfr!2sfr!4v1626255358640!5m2!1sfr!2sfr" width="600" height="450" style={{border:0}}></iframe>
                    <h3>Logement</h3>
                    <p> Le domaine dispose de 60 couchages répartis sur 3 gîtes (<a href="https://photos.app.goo.gl/5msnoFsFNR3WZPiu6" target="_blank" rel="noopener noreferrer">voir quelques photos</a>). Nous serons environ 90 personnes donc tout le monde ne pourra pas y loger. Les gîtes étant déjà payés à l'avance par les mariés, une contribution sera demandée pour ceux qui y logeront.</p>
                    <p>Alternativement, pour ceux qui souhaiteraient plus de confort, deux hôtels sont à 3km du domaine, avec des nuits à 50€ pour deux personnes environ&nbsp;:
                    l'<a href="https://www.booking.com/hotel/fr/le-bourgogne-cuiseaux.fr.html" target="_blank" rel="noopener noreferrer">hôtel de Bourgogne</a> et
                    l'<a href="https://www.booking.com/hotel/fr/restaurant-vuillot.fr.html"target="_blank" rel="noopener noreferrer">hôtel Vuillot</a>.</p>
                    <p>Il est aussi possible de planter la tente sur le domaine pour les plus vaillants.</p>
                </SideBySide>
                <SideBySide title="Tourisme" image={this.state.images[4]} textRight={false}>
                    <section id="tourisme">
                        <p>
                            Pour ceux qui comptent profiter de leur passage dans la région pour visiter et découvrir le Jura. Voici quelques conseils&nbsp;:
                        </p>
                        <ul>
                            <li><b>Château-Chalon</b><br/>Magnifique petit village juché sur son rocher avec des beaux points de vue.
                                De nombreuses caves permettent d'y déguster le vins jaune dont c'est la capitale !</li>
                            <li><b>Beaume-les-Messieurs</b><br />Site clunisien et « Plus Beaux Villages de France », est un concentré de merveilles avec ses « reculées » typiques du Jura, ses 4 belvédères, ses cascades de Tufs, sa grotte, et son abbaye impériale du XIIème siècle.</li>
                            <li>
                                Le site <a href="https://www.jura-tourism.com/" target="_blank"  rel="noopener noreferrer">Jura Tourisme</a> donne de nombreuses informations sur le département, dont notamment <a href="https://www.jura-tourism.com/essentiel-du-jura/situation-et-territoires/pays-de-lons-bresse-vignoble-et-revermont/" target="_blank" rel="noopener noreferrer">ce trajet autour de Voiteur</a>, et <a href="https://www.jura-tourism.com/essentiel-du-jura/situation-et-territoires/pays-des-lacs-et-petite-montagne/" target="_blank" rel="noopener noreferrer">cette boucle sur les lacs</a> près de la salle des fêtes.
                            </li>
                        </ul>
                    </section>
                </SideBySide>

                <SideBySide title="Liste de mariage" image={this.state.images[5]} textRight={true}>
                    <section id="liste-mariage">
                        <p>
                        Pour ceux qui le désirent, voici une petite sélection de cadeaux qui nous fairaient plasir:
                        </p>
                        <ul>
                            <li>
                                <img className="liste-mariage-icon" alt="Chili" src={chili}/><br />
                                <div>Notre voyage de noces au Chili et/ou en Argentine</div><br />
                                <a className="button" href="https://paypal.me/pools/c/8yjeHGtEYo"><div>Contribuer</div></a>
                            </li>
                            <li>
                                <img className="liste-mariage-icon" alt="Association" src={association}/><br />
                                <div>Un don pour l'association <a href="https://www.chainedelespoir.org">la chaine de l'espoir</a>.</div><br />
                                <a className="button" href="https://paypal.me/pools/c/8yjgOX6wrk"><div>Contribuer</div></a>
                            </li>
                            <li>
                                <img className="liste-mariage-icon" alt="Poeles" src={poeles}/><br />
                                <div>Des poêles de qualité pour cuisiner des bons petits plats.</div><br />
                                <a  className="button" href="https://www.paypal.com/pools/c/8yjEN5Zdnk"><div>Contribuer</div></a>
                            </li>
                        </ul>
                    </section>
                </SideBySide>
                <div className="last-infos">
                    <h1>On vous attend&nbsp;!</h1>
                    <p>
                        N'oubliez pas de <a target="_blank" rel="noopener noreferrer" href={rsvpMailToHref}>confirmer votre présence</a> <br />et pour toute autre question n'hésitez pas à <a target="_blank" href={mailToHref} rel="noopener noreferrer">nous contacter</a>.
                    </p>
                </div>
                {this.renderTrombinoscope()}
                <div style={{display: 'flex',height: 200, width: '100vw', justifyContent: 'center', backgroundImage: `url(${tabobine})`, backgroundRepeat: 'repeat-x', backgroundColor: 'black', backgroundSize: 'contain'}}>
                </div>
            </main>
            {this.renderOnboardingLink()}
        </div>
    }
}

export default wrapAccountAware(Main);