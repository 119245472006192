import * as React from 'react';
import {ComponentType, ComponentSpec} from 'react';
import createReactClass from 'create-react-class';
import { Subtract, getDisplayName } from './HOC';
import { AuthenticatedUser, Locale, User} from '../../common/magoliw';
import {getAccount, tokenLocalStorageKey} from './server';


export type AccountInfoLoadingState = 'not-started' | 'loading' | 'loaded';

export interface State {
    user: AuthenticatedUser,
    loadingState: AccountInfoLoadingState,
}

export interface PropsFromAccountAware {
    user: AuthenticatedUser,
    accountInfoLoaded: boolean,
    invalidateAccountInfo: () => void,
    isAdminNow: () => boolean,
    isOrgaNow: () => boolean,
    isScreenNow: () => boolean,
    locale: () => Locale,
}

type AccountInfoListener = (accountInfo: AuthenticatedUser) => void;
export const NON_LOGGED: AuthenticatedUser = {id: -1, firstName: '', lastName: '', image: '', token: '', table: '', email: '', tel: '', lang: 'fr'};

// Share for all components using this HOC
let globalAccountInfo: AuthenticatedUser = NON_LOGGED;
let globalLoadingState: AccountInfoLoadingState = 'not-started';
let globalListeners: AccountInfoListener[] = [];

export function extractAccountInforFromLocation(): [string, boolean] | undefined {
    let hash = window.location.hash;
    if (hash !== undefined && hash !== '') {
        if (hash.startsWith('#')) {
            hash = hash.substring(1);
        }
        const parts = hash.split('/');
        let persist = true;
        let token = hash;
        if (parts[parts.length - 1] === 'nopersist') {
            persist = false;
        }
        if (parts.length > 1) {
            token = parts[parts.length - 2 ];
        }
        return [token, persist]
    }
    return undefined;
}

export function tryPersistToken() {
    const info = extractAccountInforFromLocation();
    if (info === undefined) {
        return;
    }
    const [token, persist] = info;
    if (!persist) {
        return;
    }
    const currentToken = localStorage.getItem(tokenLocalStorageKey);
    if (token !== currentToken) {
        localStorage.setItem(tokenLocalStorageKey, token);
        invalidate();
    }
}

export function tryRetrieveToken() {
    const info = extractAccountInforFromLocation();
    if (info !== undefined) {
        const [token,] = info;
        return token;
    }
    return localStorage.getItem(tokenLocalStorageKey);
}


export const setAccountInfo = (accountInfo: AuthenticatedUser) => {
    globalLoadingState = 'loaded';
    globalAccountInfo = accountInfo;
    for (let waiter of globalListeners) {
        waiter(accountInfo);
    }
};

export const isAdmin = (accountInfo: AuthenticatedUser) => accountInfo.id === 0;
export const isOrga = (accountInfo: AuthenticatedUser) => accountInfo.id >= 0 && accountInfo.id < 10;
export const isScreen = (accountInfo: AuthenticatedUser) => accountInfo.id === 1000;
export const locale = (accountInfo: AuthenticatedUser): Locale => accountInfo.lang;
export const isUserScreen = (user: User) => user.id === 1000;

const ensureAccountInfoIsLoading = async () => {
    if (globalLoadingState !== 'not-started') {
        return;
    }
    globalLoadingState = 'loading';
    try {
        const account = await getAccount();
        setAccountInfo(account);
    } catch (e) {
        setAccountInfo(NON_LOGGED);
    }
};

export const invalidate = () => {
    globalLoadingState = 'not-started';
    ensureAccountInfoIsLoading();
};


const removeListener = (l: AccountInfoListener) => {
    const index = globalListeners.indexOf(l);
    if (index !== -1) {
        globalListeners.splice(index, 1);
    }
};

export const awaitAccountInfo = (): Promise<AuthenticatedUser> => {
    if (globalLoadingState === 'loaded') {
        return Promise.resolve(globalAccountInfo);
    } else {
        ensureAccountInfoIsLoading();
        return new Promise((resolve, reject) => {
            const listener: AccountInfoListener = accountInfo => {
                resolve(accountInfo);
                removeListener(listener);
            };
            globalListeners.push(listener);
        });
    }
};


function wrapAccountAware<P extends object>(Component: ComponentType<P>) : ComponentType<Subtract<P, PropsFromAccountAware>>{
    let accountInfoListener: AccountInfoListener;
    const displayName = `AccountAwareHOC(${getDisplayName(Component)})`;
    const spec: ComponentSpec<Subtract<P, PropsFromAccountAware>, State> = {
        displayName,

        // Business Logic

        // LifeCycle

        getInitialState() {
            if (accountInfoListener === undefined) {
                accountInfoListener = spec.onAccountInfoChange.bind(this);
            }
            const state = {user: globalAccountInfo, loadingState: globalLoadingState};
            return state;
        },

        async componentDidMount() {
            globalListeners.push(accountInfoListener);
            if (this.state.loaded !== globalLoadingState) {
                // If the account info was loaded between our getInitialState and our componentDidMount:
                this.setState({accountInfo: globalAccountInfo, loadingState: globalLoadingState});
            }
            ensureAccountInfoIsLoading();
        },

        componentWillUnmount() {
            removeListener(accountInfoListener);
        },

        // Event Handling

        onAccountInfoChange(accountInfo: AuthenticatedUser) {
            this.setState({user: accountInfo, loaded: true});
        },

        // Rendering
        render() {
            const state: State = this.state;
            return <Component {...this.props}
                accountInfoLoaded={state.loadingState === 'loaded'}
                user={state.user}
                isAdminNow={() => isAdmin(state.user)}
                isOrgaNow={() => isOrga(state.user)}
                isScreenNow={() => isScreen(state.user)}
                locale={() => locale(state.user)}/>;
        }
    };
    return createReactClass<Subtract<P, PropsFromAccountAware>, State>(spec);
};

export default wrapAccountAware;
export {
    globalAccountInfo,
    globalLoadingState,
    globalListeners,
    removeListener,
};