import React from 'react';
import Admin from './Admin';
import Player from './Player';
import Screen from './Screen';
import wrapAccountAware, { PropsFromAccountAware } from '../AccountAwareHOC';

import './Main.css'
import { BtServerState, isBtAdmin } from './model';
interface PropsFromParent {
    serverState: BtServerState,
    usersCount: number,
}

type Props = PropsFromAccountAware & PropsFromParent;

class Main extends React.Component<Props> {

    public render() {
        if (this.props.isScreenNow()) {
            return <Screen serverState={this.props.serverState} usersCount={this.props.usersCount}/>;
        }
        if (isBtAdmin(this.props.user)) {
            return <Admin serverState={this.props.serverState} />;
        }
        return <Player serverState={this.props.serverState} user={this.props.user}/>;
    }
}

export default wrapAccountAware(Main);