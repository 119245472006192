import React from 'react';
import Admin from './Admin';
import Player from './Player';
import Screen from './Screen';
import wrapAccountAware, { PropsFromAccountAware } from '../AccountAwareHOC';
import {QuizzProps } from './model';

import './Quizz.css'

type Props = QuizzProps & PropsFromAccountAware;

class Main extends React.Component<Props> {


    public render() {
        if (this.props.isScreenNow()) {
            return <div className="quizz"><Screen serverState={this.props.serverState} usersCount={this.props.usersCount} /></div>;
        }
        if (this.props.isAdminNow()) {
            return <div className="quizz"><Admin serverState={this.props.serverState} usersCount={this.props.usersCount} /></div>;
        }
        return <div className="quizz"><Player serverState={this.props.serverState} user={this.props.user} usersCount={this.props.usersCount} /></div>;
    }
}

export default wrapAccountAware(Main);