import React from 'react';
import { QuizzProps } from './model';
import { sendMessage } from '../server';
import { isMultipleChoiceQuestion, QuizzFreeCorrection, QuizzAnswer } from '../common/magoliw';

export default class Admin extends React.Component<QuizzProps> {

    // Event Handling

    private onNextQuestion() {
        sendMessage({
            type: 'next-question'
        });
    }

    private onPickCorrection(c: QuizzFreeCorrection) {
        sendMessage({
            type: 'quizz-correction',
            c,
        });
    }

    // Rendering

    private renderAction() {
        if (this.props.serverState.qid === -1) {
            return <div onClick={() => this.onNextQuestion()}>Démarrer</div>
        }
        if (this.props.serverState.endedQuestions.has(this.props.serverState.qid)) {
            if (this.props.serverState.qid === this.props.serverState.questions.length - 1) {
                return <div onClick={() => sendMessage({type: 'change-main-view', view: 'demodj'})}>DemoDJ</div>;
            } else {
                return <div onClick={() => this.onNextQuestion()}>Question suivante</div>
            }
        } else {
            return <div>
                {this.renderCorrections()}
                <div onClick={() => sendMessage({type: 'quizz-question-end', qid: this.props.serverState.qid})}>Fin question</div>
            </div>
        }
    }

    private getTablesHavingAnswered(): string[] {
        const set = new Set(this.props.serverState.answers.filter(a => a.question === this.props.serverState.qid).map(a => a.user.table));
        return Array.from(set);
    }

    private renderCorrection(question: number, text: string, correction: QuizzFreeCorrection | undefined) {
        const yesClass = correction === undefined ? 'quizz-correction-correct' : correction.accepted ? 'quizz-correction-current-correct' : 'quizz-correction-other';
        const noClass = correction === undefined ? 'quizz-correction-wrong' : correction.accepted ? 'quizz-correction-other' : 'quizz-correction-current-wrong';
        return <div style={{display: 'flex'}}>
            <div className={yesClass} onClick={() => this.onPickCorrection({question, text, accepted: true})}>V</div>
            <div className={noClass} onClick={() => this.onPickCorrection({question, text, accepted: false})}>X</div>
        </div>
    }

    private renderAnswerAndCorrection(corrections: QuizzFreeCorrection[], answer: QuizzAnswer) {
        const a = answer.answer;
        if (typeof a === 'string') {
            const matchingCorrections = corrections.filter(c => c.text === a);
            const correction = matchingCorrections.length === 0 ? undefined : matchingCorrections[matchingCorrections.length - 1];
            return <div style={{display: 'flex'}}><div className="quizz-correction-text">{a}</div>{this.renderCorrection(answer.question, a, correction)}</div>
        } else {
            return null;
        }
    }

    private renderCorrections() {
        const qid = this.props.serverState.qid;
        const question = this.props.serverState.questions[qid];
        const corrections = this.props.serverState.corrections.filter(c => c.question === qid);
        if (isMultipleChoiceQuestion(question)) {
            return <div>Tables ayant répondu: {this.getTablesHavingAnswered().join(', ')}</div>
        } else {
            return <div>Réponses:
                {this.props.serverState.answers
                .filter(a => a.question === this.props.serverState.qid)
                .map(answer => this.renderAnswerAndCorrection(corrections, answer))
                }
            </div>

        }
    }

    public render() {
        return <div className="demodj-root">
            <h1>Quizz Admin</h1>
            <div>Question {this.props.serverState.qid + 1} / {this.props.serverState.questions.length}</div>
            {this.renderAction()}
            <p>{this.props.usersCount} joueurs connectés</p>
        </div>
    }
}