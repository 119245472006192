import React from 'react';
import "./Countdown.css";

interface State {
    ms: number,
}

export default class CountDown extends React.Component<{}, State> {

    private interval: any;

    public constructor(props: {}) {
        super(props);
        this.state = {
            ms: Date.now(),
        }
    }

    public componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1000);
    }

    public componentWillUnmount() {
        clearInterval(this.interval);
    }

    private tick() {
        this.setState({ms: Date.now()});
    }

    private renderCircle(n : number, period: string, color: string): JSX.Element {
        return <div key={period} className="countdown" style={{
            backgroundColor: `var(${color})`}}>
            <h2 style={{ lineHeight: '0.8em'}}>
                <div>{n}</div>
                <div style={{fontSize: '0.5em'}}>{period}</div>
            </h2>
        </div>;
    }

    public render() {
        const target = new Date(2021, 7, 7, 15, 0, 0, 0);
        let ms = target.getTime() - this.state.ms;
        let seconds = 0;
        let minutes = 0;
        let hours = 0;
        let days = 0;
        seconds = Math.floor(ms / 1000);
        ms = ms % 1000;
        if (ms < 0) {
            return null;
        }
        const circles: JSX.Element[] = [];
        minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        days = Math.floor(hours / 24);
        hours = hours % 24;
        circles.push(this.renderCircle(days, 'jours', '--raspberry'));
        circles.push(this.renderCircle(hours, 'heures', '--orange'));
        circles.push(this.renderCircle(minutes, 'minutes', '--yellow'));
        circles.push(this.renderCircle(seconds, 'secondes', '--green'));
        return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>{circles}</div>;
    }
}