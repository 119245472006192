import { QuizzQuestion, QuizzAnswer, QuizzFreeCorrection, isMultipleChoiceQuestion } from "../common/magoliw";

export interface QuizzServerState {
    qid: number,
    questions: QuizzQuestion[],
    answers: QuizzAnswer[],
    corrections: QuizzFreeCorrection[],
    endedQuestions: Set<number>,
} 

export interface QuizzProps {
    serverState: QuizzServerState,
    usersCount: number,
}

export function isCorrectAnswer(quizzServerState: QuizzServerState, answer: QuizzAnswer): boolean | undefined {
    const qid = answer.question;
    const question = quizzServerState.questions[qid];
    if (isMultipleChoiceQuestion(question)) {
        return question.correct === answer.answer;
    } else {
        const corrections = quizzServerState.corrections.filter(c => c.question === qid && c.text === answer.answer);
        if (corrections.length === 0) {
            return undefined;
        }
        return corrections[corrections.length -1].accepted;
    } 
}